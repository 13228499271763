.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 1400px;
    z-index: -1;

    .line {
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0;
        left: 50%;
        background: rgba(255, 255, 255, 0.1);
        overflow: hidden;

        &::after {
            content: '';
            display: block;
            position: absolute;
            height: 15vh;
            width: 2px;
            top: -50%;
            left: 0;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
            animation: drop 10s 0s infinite;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in;
        }

        &:nth-child(1) {
            left: 0;

            &::after {
                animation-delay: 2s;
            }
        }

        &:nth-child(3) {
            right: 0;
            left: unset;

            &::after {
                animation-delay: 2.5s;
            }
        }
    }
}

@keyframes drop {
    0% {
        top: -50%;
    }

    100% {
        top: 110%;
    }
}