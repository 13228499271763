$primary: #D6EF0E;
$secondary-dark: #1E1E1E;
$secondary-bright: #FFFFFF;
$secondary-bright-transparent: rgba(255, 255, 255, 0.72);

$c_0: #000000;
$c_1: #353540;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #090F1D;
  color: $secondary-bright;
  font-family: 'Jost', sans-serif;
  overflow-x: hidden;
}

.container {
  width: 1290px;
  margin: 0 auto;
}

.particles-js {
  display: block;
  position: fixed !important;
  z-index: -1;
  top: 0;
  left: 0;
  box-sizing: border-box;
  opacity: .35;
}

.button {
  font-family: 'Jost', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: $primary;
  line-height: 120%;
  padding: 12px 40px;
  background: transparent;
  border-radius: 8px;
  border: 3px solid $primary;
  box-sizing: border-box;
  cursor: pointer;
  transition: .4s all;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;

  &:hover {
    background-color: $primary;
    color: $secondary-bright;
  }

  &[disabled] {
    pointer-events: none;
    opacity: .5;
  }
}

#mouse-parallax-container {
  overflow: visible !important;
}

.sub-button {
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: $secondary-bright;
  text-decoration: none;
}

h1 {
  font-size: 64px;
  line-height: 120%;
}

h2 {
  font-size: 48px;
  line-height: 120%;
}

.title {
  font-family: 'Times New Roman', Times, serif;
  font-weight: 700;
  margin: unset;

  span {
    color: $primary;
  }
}

.text {
  font-size: 16px;
  line-height: 160%;
}

.header {
  .container {
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: 249px;
      right: 220px;
      width: 631px;
      height: 458px;
      background: #1F413D;
      filter: blur(200px);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      width: 352px;
      height: 221px;
      background: #1A2652;
      filter: blur(125px);
      bottom: 39px;
      right: -58px;
    }
  }


  .header__menu {
    display: flex;
    justify-content: space-between;
    padding: 28px 0;

    .header__nav {
      display: flex;
      gap: 40px;
      align-items: center;

      .nav__link {
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        color: $secondary-bright-transparent;
        text-decoration: none;
        transition: .4s all;
        position: relative;

        &:after {
          content: '';
          background-color: $primary;
          height: 1px;
          display: block;
          width: 0%;
          transition: .4s all;
          position: absolute;
          bottom: -1px;
        }

        &.active,
        &:hover {
          color: $secondary-bright;

          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  .header__content {
    padding: 92px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header__info {
      width: 544px;

      .header__stats {
        margin-top: 72px;
        display: flex;
        gap: 94px;

        .stats__item {
          padding-right: 28px;
          border-right: 1px solid $primary;

          &:last-child {
            border-right: unset;
          }

          .stats__amount {
            font-family: 'Times New Roman', Times, serif;
            font-weight: 700;
            font-size: 48px;
            line-height: 100%;

            span {
              color: $primary;
            }
          }

          .stats__topic {
            font-size: 16px;
            line-height: 160%;
            margin-top: 12px;
          }
        }

      }
    }

    .header__lot {
      width: 575px;
      height: 637px;
      position: relative;

      .header__lot-nft {
        margin-left: 60px;
        position: relative;
        width: 464px;
        height: 544px;
        background-image: url(./img/artwork-of-the-week.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 24px;

        .header__lot-mark {
          top: 223px;
          left: -60px;
          width: 171.5px;
          height: 171.59px;
          position: absolute;

          .header__lot-mark-star {
            width: 35px;
            height: 35px;
            position: absolute;
            top: calc(50% - 35px / 2);
            left: calc(50% - 35px / 2);
            background-image: url(./img/star.svg);
            background-size: cover;
            background-repeat: no-repeat;
          }

          .header__lot-mark-text {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(./img/artwork-of-the-week.svg);
            background-size: cover;
            background-repeat: no-repeat;
            animation: lot-mark-text 60s linear infinite;

            @keyframes lot-mark-text {
              0% {
                transform: rotate(0deg);
              }

              100% {
                transform: rotate(360deg);
              }
            }
          }
        }
      }

      .header__lot-auction {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 304px;
        background-color: #131E3A;
        border-top: 1px solid $primary;
        box-shadow: 0px 9px 50px rgba(23, 36, 65, 0.04);
        border-radius: 16px;
        box-sizing: border-box;
        padding: 20px;

        .header__lot-stats {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .header__lot-stats-section {
            font-size: 16px;
            line-height: 160%;
            color: $primary;
          }

          .header__lot-stats-value {
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
            margin-top: 8px;
          }
        }

        .header__lot-auction-button {
          margin-top: 24px;
          width: 100%;
        }
      }
    }
  }

}

.info-block {
  .info-block__text {
    margin-top: 20px;
  }

  .info-block__buttons {
    margin-top: 40px;
    display: flex;
    gap: 36px;
    align-items: center;
  }

  .info-block__subscribe {
    width: 100%;
    height: 60px;
    display: flex;
    background: #131E3A;
    border-radius: 8px;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 4px;
    gap: 4px;
    margin-top: 40px;

    .info-block__subscribe-input {
      font-family: 'Jost', sans-serif;
      color: $secondary-bright;
      font-size: 16px;
      line-height: 160%;
      background: transparent;
      border: unset;
      width: 100%;
      outline: unset;
      padding: 0 20px;

      &::placeholder {
        color: $secondary-bright-transparent;
      }
    }
  }
}

.weekly-arts {
  padding: 92px 0;

  .container {
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      bottom: -135px;
      right: -166px;
      width: 434px;
      height: 305px;
      background: #1F413D;
      filter: blur(175px);
    }

    .weekly-arts__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .weekly-arts__title {
        font-size: 48px;
        line-height: 120%;
        max-width: 550px;
      }
    }

    .weekly-arts__content {
      margin-top: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .weekly-arts__item {
        width: 410px;
        background: #131E3A;
        border-top: 1px solid $primary;
        border-radius: 28px;
        padding: 20px;
        box-sizing: border-box;

        .weekly-arts__item-nft {
          width: 100%;
          height: 340px;
          border-radius: 16px;
          background-image: url(./img/weekly/cyberpunk-cocomo.jpg);
          background-size: cover;
          background-repeat: no-repeat;
        }

        .weekly-arts__item-info {
          margin-top: 23.5px;

          .weekly-arts__item-info-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .weekly-arts__item-info-title {
              font-weight: 700;
              font-size: 24px;
              line-height: 120%;
            }

            .weekly-arts__item-info-price {
              display: flex;
              gap: 4px;
              align-items: center;
              justify-content: flex-end;

              .weekly-arts__item-crypto-icon {
                width: 24px;
                height: 24px;
                background-image: url(./img/ethereum.svg);
                background-size: cover;
                background-repeat: no-repeat;
              }

              .weekly-arts__item-price {
                font-weight: 700;
                font-size: 16px;
                line-height: 120%;
              }
            }
          }

          .weekly-arts__cta {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .weekly-arts__timer {
              .weekly-arts__timer-title {
                font-size: 14px;
                line-height: 160%;
                color: #838382;
              }

              .weekly-arts__timer-counter {
                margin-top: 4px;
                display: flex;
                gap: 12px;
                align-items: center;
                justify-content: flex-start;
                font-weight: 700;
                font-size: 16px;
                line-height: 120%;

                .weekly-arts__timer-icon {
                  width: 24px;
                  height: 24px;
                  background-image: url(./img/clock.svg);
                  background-size: cover;
                  background-repeat: no-repeat;
                }
              }
            }
          }
        }
      }
    }
  }
}

.create-and-sell {
  padding: 92px 0;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      bottom: -47px;
      right: -16px;
      width: 346px;
      height: 128px;
      background: #1F413D;
      filter: blur(125px);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      bottom: -221px;
      left: -140px;
      width: 291px;
      height: 257px;
      background: #131E3A;
      filter: blur(175px);
    }


    .create-and-sell__info {
      width: 380px;
    }

    .create-and-sell__nfts {
      width: 740px;
      height: 559px;
      position: relative;

      .create-and-sell__nft-lower {
        width: 410px;
        height: 475px;
        border-radius: 24px;
        background-image: url(./img/create-and-sell/lower.jpg);
        z-index: -1;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, .8);
      }

      .create-and-sell__nft-upper {
        width: 410px;
        height: 400px;
        border-radius: 24px;
        background-image: url(./img/create-and-sell/upper.jpg);
        box-shadow: 5px -5px 20px rgba(0, 0, 0, .8);
      }
    }
  }
}

.popular {
  padding: 92px 0;

  .container {
    .popular__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 32px;

      .popular__title {
        width: 320px;
      }
    }

    .popular__content {
      margin-top: 64px;
      display: grid;
      grid-template-columns: repeat(18, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 30px;
      height: 652px;

      .popular__content-item {
        &:hover {
          transform: rotateX(7deg) translateY(-6px);

          :before {
            opacity: 0.6;
            transform: rotateX(7deg) translateY(-6px) scale(1.05);
          }

          :after {
            opacity: 0.6;
            transform: translateY(0%);
          }
        }

        border-radius: 16px;
        overflow: hidden;
        text-decoration: none;
        color: $secondary-bright;
        transition: .4s all;
        transform-style: preserve-3d;
        position: relative;
        z-index: 1;
        perspective: 4000px;

        &-animation-helper {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          padding: 24px;
          box-sizing: border-box;
          gap: 12px;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          background-size: cover;
          perspective-origin: 50% 50%;
          transform-style: preserve-3d;
          transform-origin: top center;
          will-change: transform;
          transform: skewX(.001deg);
          transition: .4s all;

          &:after {
            display: block;
            content: '';
            position: absolute;
            z-index: 100;
            top: 0;
            left: 0;
            width: 100%;
            height: 125%;
            background: linear-gradient(226deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 35%, rgba(255, 255, 255, 0.2) 42%, rgba(255, 255, 255, 0) 60%);
            transform: translateY(-20%);
            will-change: transform;
            transition: .4s all;
            pointer-events: none;
          }

        }

        &::before {
          display: block;
          content: '';
          position: absolute;
          top: 5%;
          left: 5%;
          width: 90%;
          height: 90%;
          background: rgba($c_0, 0.5);
          box-shadow: 0 6px 12px 12px rgba($c_0, 0.4);
          will-change: opacity;
          transform-origin: top center;
          transform: skewX(.001deg);
          transition: .4s all;
        }

        &:nth-child(3) {
          grid-area: 2 / 1 / 3 / 7;
        }

        &:nth-child(4) {
          grid-area: 2 / 7 / 3 / 13;
        }

        &:nth-child(5) {
          grid-area: 1 / 13 / 3 / 19;
        }

        &:nth-child(1) {
          grid-area: 1 / 1 / 2 / 5;
        }

        &:nth-child(2) {
          grid-area: 1 / 5 / 2 / 13;
        }


        .popular__content-item-avatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-size: cover;
          background-repeat: no-repeat;
          transition: .4s all;
        }

        .popular__content-item-info {
          text-decoration: none;
          color: $secondary-bright;
          display: block;
          transition: .4s all;

          .popular__content-item-label {
            font-size: 14px;
            line-height: 160%;
            text-decoration: none;
          }

          .popular__content-item-name {
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
            text-decoration: none;
          }
        }
      }

    }
  }
}

.subscribe {
  padding: 92px 0;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: -101px;
      left: -199px;
      width: 513px;
      height: 358px;
      background: #1F413D;
      filter: blur(175px);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      bottom: -67px;
      left: 40px;
      width: 345px;
      height: 93px;
      background: #1A2652;
      filter: blur(125px);
    }

    .subscribe__info {
      max-width: 516px;
    }

    .subscribe__nfts {
      width: 630px;
      height: 458px;
      position: relative;

      .subscribe__nft-lower {
        width: 405px;
        height: 376px;
        border-radius: 20px;
        transform: rotate(-11.19deg);
        background-image: url(./img/subscribe/lower.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, .8);
      }

      .subscribe__nft-upper {
        width: 281px;
        height: 347px;
        border-radius: 20px;
        background-image: url(./img/subscribe/upper.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, .8);
      }
    }
  }
}

.footer {
  padding: 92px 0;

  .footer__navigation {
    padding-bottom: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #838382;

    .footer__discover {
      max-width: 240px;

      .footer__socials {
        display: flex;
        gap: 12px;
        margin-top: 24px;

        .footer__socials-item {
          &:hover {
            svg {
              path {
                &:first-child {
                  fill: $primary
                }
              }
            }
          }

          path {
            transition: .4s all;
          }
        }

      }
    }


    .footer__links {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      gap: 94px;

      .footer__links-section {
        .footer__links-section-title {
          font-weight: 700;
          font-size: 20px;
          line-height: 120%;
          margin-bottom: 24px;
        }

        .footer__link {
          font-weight: 400;
          font-size: 16px;
          line-height: 160%;
          color: $secondary-bright;
          text-decoration: none;
          display: block;
          margin-top: 8px;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }

  .footer__copyright {
    padding-top: 48px;
    display: flex;
    justify-content: space-between;
    align-content: center;

    .footer__copyright-links {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 28px;

      .footer__copyright-link {
        text-decoration: none;
      }
    }

    .footer__copyright-link {
      color: $secondary-bright;

    }
  }
}

.Toastify__toast {
  font-family: 'Jost', sans-serif !important;
  line-height: 1.2em;
}

.responsive-protection{
  position: fixed;
  background-color: #000000;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  img{
    max-width: 100%;
    max-height: 400px;
  }
}